import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Sidebar from './Common/Sidebar';
import gsap from 'gsap';
import ScrollTrigger from "gsap/ScrollTrigger";
import Slider from './Common/Slider';
import Clientes from './Common/Clientes';
import { BrowserView, MobileView,isSafari } from 'react-device-detect';
import homevideo from '../assets/uppout_site_background.mp4';
import arrowdown from '../assets/Arrow_Black.png';
import arrowup from '../assets/arrow-up.png'
import arrowupwhite from '../assets/arrow-up-white.png'
import arrowupgreen from '../assets/arrow-up-green.png'
import servicedesktop from '../assets/Services_Desktop.png'
import servicemobile from '../assets/Services_Mobile.png'
import Navbar from './Common/Navbar';
import LoadingUI from './Common/LoadingUI';
import ClientsMob from './Common/ClientsMob';
import Assinatura from '../assets/AssinaturaSite.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faVolumeMute,faVolumeHigh} from '@fortawesome/free-solid-svg-icons'
const Home = () => {
    const [isLoading, setIsLoading] = useState(true);
const [sidebarcolor,setSidebarcolor] = useState("White");
const main = useRef();
const section1 = useRef();
const section2 = useRef();
const section3 = useRef();
const section4  = useRef();
const section5  = useRef();
const arrowicon = useRef();
const [modalStatus, setModalStatus] = useState(false);
const [modalStatussec2, setModalStatussec2] = useState(false)
const [logoTooltip, setLogoTooltip] =useState(false)
const [cursorPosition, setCursorPosition] = useState({ top: 0, left: 0 })
const [hidelogo, setHidelogo] = useState(false);
const [isMuted, setIsMuted] = useState(true);
const playerRef = useRef(null);
const handleUnmute = () => {
  setIsMuted(false);
};


useEffect(() => {
  // Load the YouTube IFrame Player API
  const tag = document.createElement('script');
  tag.src = 'https://www.youtube.com/iframe_api';
  const firstScriptTag = document.getElementsByTagName('script')[0];
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

  // Initialize the YouTube player once the API is ready
  window.onYouTubeIframeAPIReady = () => {
    playerRef.current = new window.YT.Player('player', {
      videoId: 'KMW8Boon7MA',
      playerVars: {
        autoplay: 1,
        mute: 1,
        loop: 1,
        rel: 0,
        controls: 0,
        modestbranding: 1,
        showinfo: 0,
        wmode: 'transparent',
      },
      events: {
        onStateChange: onPlayerStateChange,
      },
    });
  };
}, []);

useEffect(() => {
  if (playerRef.current) {
    if (isMuted) {
      playerRef.current.mute();
    } else {
      playerRef.current.unMute();
    }
  }
}, [isMuted]);

const onPlayerStateChange = (event) => {
  if (event.data === window.YT.PlayerState.ENDED) {
    event.target.seekTo(0); // Restart the video
  }
};
const handleMuteToggle = () => {
  setIsMuted((prevIsMuted) => !prevIsMuted);
};
const onMouseMove = e =>
  setCursorPosition({ top: e.pageY+30, left: e.pageX+30 });

gsap.registerPlugin(ScrollTrigger)
//start scroll snap code
useLayoutEffect(() => {

  ScrollTrigger.defaults({
    toggleActions: "restart pause resume pause",
    scroller: main.current
  });

//gsap.to(arrowicon.current, { rotation: "360" });
gsap.to(section1.current, {
  scrollTrigger: {
    trigger:section1.current,
    start: 'top center',
    end:'bottom center',
    //markers:true,
      toggleActions: "restart none restart none"
  }, 
onUpdate: setsection,
onUpdateParams: [1],

 });
  gsap.to(section2.current, {
    scrollTrigger: {
      trigger:section2.current,
      start: 'top center',
      end:'bottom center',
      //markers:true,
        toggleActions: "restart none restart none"
    }, 
 onUpdate: setsection,
 onUpdateParams: [2],
   });
   gsap.to(section3.current, {
    scrollTrigger: {
      trigger:section3.current,
      start: 'top center',
      end:'bottom center',
     // markers:true,
        toggleActions: "restart none restart none"
    }, 
 onUpdate: setsection,
 onUpdateParams: [3],
 
   });

   gsap.to(section4.current, {
    scrollTrigger: {
      trigger:section4.current,
      start: 'top center',
      end:'bottom center',
     // markers:true,
        toggleActions: "restart none restart none"
    }, 
 onUpdate: setsection,
 onUpdateParams: [4],
   });

   gsap.to(section5.current, {
    scrollTrigger: {
      trigger:section5.current,
      start: 'top center',
      end:'bottom center',
     // markers:true,
        toggleActions: "restart none restart none"
    }, 
 onUpdate: setsection,
 onUpdateParams: [5],
   });
}, []);


//end scroll snap code
const setsection = (secno) =>{
  if(secno == 2 || secno == 4 ){
    //console.log("black")
    setSidebarcolor("Black")
    try {
       main.current.classList.add("blacksidebar") 
         if(main.current.classList.contains("whitesidebar")){
      main.current.classList.remove("whitesidebar")
    }
    } catch (error) {
      
    }
  

  } else if(secno == 3 || secno == 1 || secno == 5){
    //console.log("white")
    setSidebarcolor("White")
    try {
      main.current.classList.add("whitesidebar")
        if(main.current.classList.contains("blacksidebar")){
      main.current.classList.remove("blacksidebar")
    }
    } catch (error) {
      
    }
    

  }
  if(secno == 4 ){
    setHidelogo(true);
  }
  else{
    setHidelogo(false);
  }

    }
useEffect(() => {
  setTimeout(() => {
      setIsLoading(false);
  },3000);
  
}, []);

const scrolldown = (sec) =>{
console.log(sec)
sec.current.scrollIntoView({behavior: "smooth",block: "start", inline: "start"})

}
  

  return (
   <>
    <LoadingUI isLoading={isLoading} />
 
  <main id="main" ref={main} className="w-full  " >
  <Navbar colortheme={sidebarcolor} hidelogo={hidelogo} />
  <Sidebar colortheme={sidebarcolor} borderstatus={true} />  
<section id="section1" ref={section1}   className="sections relative w-full lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]   h-[100vh]  justify-between text-white flex flex-col blackcursor" >

    <div className=" h-[calc(100vh_-_30px)] overflow-hidden  pt-28 lg:pt-0 flex  ">
 
<div className='relative w-full h-auto lg:h-full max-lg:self-center  mb-[100px] lg:mb-0 '>
  {/* Your other content here */}
  <div id="player" className=' w-[100vw] lg:w-full h-[240px] lg:h-[110%]'></div>
          
  <button className='absolute bottom-[-30px] lg:bottom-[10px] left-[10px] px-2 py-1 bg-black text-white text-xs cursor-pointer'
 onClick={handleMuteToggle}>
   <FontAwesomeIcon icon={isMuted ? faVolumeHigh : faVolumeMute}   className={`w-[14px]`} />

            
          </button>      


</div>
 

</div>

 <div className="text-center h-[20px] ">
 <img
          src={arrowdown}
          alt=""
          className="w-[80px] cursor-blackcur rotate-180 mx-auto mb-3"
          
          ref={arrowicon}
onClick={()=>{scrolldown(section2)}}
        />
 </div>
 
  </section>

{/* section with 3 blocks    */}

<section ref={section2} className={`section2 sections overflow-y-hidden w-full bg-[#111111] lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]  h-[100vh]  justify-between text-white flex flex-col whitecursor`} >
    <div  className={`w-full  flex flex-col   pt-28 lg:pt-0 relative ${isSafari ? "h-[90vh]" : "h-[100vh]"}`}>
    {modalStatussec2 ?
        <div className='absolute w-full h-full bg-black/75 z-10'>
          <div className='text-black absolute bottom-48  lg:bottom-10 left-[50%] -translate-x-[50%] w-[80%] lg:w-full  lg:max-w-2xl xl:max-w-4xl   bg-white  bordery'>
          <a className='absolute right-0 top-2 flex justify-end px-2 pt-1 cursor-pointer' onClick={()=>setModalStatussec2(!modalStatussec2)} >
         <svg className="h-10 w-10 group-hover:rotate-180 transition duration-300 ease-in-out" fill="white"
         viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" aria-hidden="true">
         <path d="M6 18L18 6M6 6l12 12" />
       </svg>
         </a>
         <div className='w-full flex flex-col lg:flex-row justify-center items-center lg:gap-20 pt-5 pb-6 lg:pb-12'>
         <p className='w-full text-[#0000ff] font-bold  text-md lg:text-3xl leading-tight flex px-6 lg:justify-end items-center'>
         <span>
         DESCOBRE O QUE<br />
PODEMOS FAZER<br />
PARA TI
         </span>
         </p>
         <p className='w-full leading-tight text-sm lg:text-2xl flex  px-6 lg:justify-start items-start pt-4 lg:pt-0 italic'>
           <span>
           <a href='mailto:geral@uppout.com' className='italic'>geral@uppout.com</a> <br /><span className='block h-[15px] lg:hidden' ></span>
         <a href='tel:+351215862031' className='italic'>+351 215 862 031</a><br /><span className='block h-[15px] ' ></span>
<a href="https://maps.app.goo.gl/rwn3PVecz5K6GfFUA" rel="nofollow" target='_blank' className='text-md lg:text-sm italic'>Rua Garridas 8,<br className='block lg:hidden' /> 1500-618 Lisboa</a>
           </span>
        
         </p>
         </div>
         </div></div>
        :""
       }
    <div className='h-[14vh] lg:h-[18vh] text-white text-[1.1em] lg:text-3xl xl:text-4xl pt-5 pl-10 xl:pl-16 '>
        <p className=' font-bold leading-normal  italic'>O que andamos a fazer</p>
        <p className='italic'>ultimamente…</p>
      
        </div>
        <div className='h-full oqueslider'>
      <Slider />
        </div>


<div className='h-[10vh] flex justify-center pt-5 mb-[90px] lg:mb-10 '>
           <button className='w-[70%] lg:max-w-lg xl:max-w-xl h-auto lg:h-[40px] xl:h-[50px]  text-themeblue font-bold text-[3vw] sm:text-sm lg:text-md xl:text-xl hover:text-sm hover:lg:text-md hover:xl:text-2xl  px-8  py-0 xl:py-2 hover:xl:py-0 hover:font-baskervville hover:italic hover:lowercase hover:text-black hover:tracking-wider bg-white relative bordery text-nowrap text-center' onClick={()=>setModalStatussec2(!modalStatussec2)}>DESCOBRE O QUE PODEMOS <span className="max-md:block "></span> FAZER PARA TI</button>
       </div>
       
</div>
      </section>

      <section id="section3" ref={section3}   className="sections w-full bg-white lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]   h-[100vh]  justify-between text-black flex flex-col blackcursor" >
    <div className=" w-full h-[100vh] flex flex-col justify-between ">
    <div className='text-black text-md lg:text-3xl xl:text-4xl  pb-2  pl-10 pt-32 lg:pt-6 cursor-blackcur ' onMouseEnter={()=> setLogoTooltip(true)}  onMouseLeave={()=>{setLogoTooltip(false)}} onMouseMove={onMouseMove}>
    <p className=' font-bold leading-normal italic'>Os nossos clientes<span className="text-[#00ff00] px-1">.</span></p>
    <p className='italic'>Crescem connosco e inspiram a nossa criatividade.</p>
    </div>
    <div className={`  z-50 leading-[0.2em] ${logoTooltip ? '' : 'hidden'} logotooltip`} style={{position: 'absolute', ...cursorPosition }}>
   <span className='text-sm font-semibold font-baskervville '>minuciosamente         
            </span><br />
            <span className='text-[0.6em] font-bold'>DISRUPTIVOS</span>
   </div>
<div className=" h-full overflow-y-scroll xl:overflow-hidden px-2 lg:px-20  xl:px-24 pt-4 xl:pt-12">
  <BrowserView>
  <Clientes />  
  </BrowserView>
  <MobileView>
  <ClientsMob />
  </MobileView>

</div>

</div>
      </section>
    
<section ref={section4}   className="sections4 relative w-full bg-[#111111] lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]    h-[100vh]  justify-center text-white flex flex-col whitecursor" >

<div className="w-full h-[75vh] overflow-hidden px-1 lg:px-20 pt-8 ">
<BrowserView className='flex  h-full justify-center items-center '>

<img
src={servicedesktop}
alt=""
className=" max-h-full w-auto"
/>

</BrowserView>
<MobileView className='flex  h-full justify-center  '>
<img
   src={servicemobile}
   alt=""
   className="h-auto max-w-[80%] mx-auto "

 />
</MobileView>

</div>

<div className="text-white text-md text-nowrap lg:text-3xl xl:text-4xl pt-10 pl-10 lg:pl-20 pb-8 lg:pb-12 h-[20vh] lg:h-[23vh] pr-4 lg:pr-0">
<p className=" font-bold leading-normal italic">Para cada desafio,</p>
<p className='italic'>uma solução diferenciadora.</p>
</div>
</section>

<section ref={section5}   className="sections4 relative w-full bg-white lg:w-[80%] xl:w-[85%] 2xl:w-[90%]  lg:ml-[20%] xl:ml-[15%] 2xl:ml-[10%]   h-[100vh]  justify-between text-white flex flex-col blackcursor" >

<div className="flex justify-center h-[40px] py-3 mb-5 hidden">

<img
   src={arrowupgreen}
   alt=""
   className="w-[90px] cursor-blackcur"
   onClick={()=>{scrolldown(section4)}}
 />

</div>
<div className=" overflow-hidden h-[100vh] px-10 lg:px-20 pr-10   flex flex-col justify-center items-center ">

<div className='h-[60vh] flex justify-center items-center'>
<img src={Assinatura} alt="" className='lg:h-full mb-24 lg:mb-0 mx-auto ' />
</div>
<div className="w-full">
<div className='flex justify-center lg:justify-end pt-10'>
            <button className='w-auto lg:max-w-sm bg-white text-[#0000ff] font-bold text-sm lg:text-lg hover:text-xl hover:font-baskervville hover:italic hover:lowercase hover:text-black hover:tracking-wider leading-tight px-6 lg:px-8 py-1 text-center  relative bordery cornerwhite text-nowrap' onClick={()=>setModalStatus(!modalStatus)}>DIZ-NOS <span className='block lg:hidden'></span> O QUE PRECISAS!</button>
            {/* <button className={`w-full lg:max-w-sm bg-white text-[#0000ff] font-bold text-md lg:text-lg hover:text-xl hover:font-baskervville hover:italic hover:lowercase hover:text-black hover:tracking-wider px-8 lg:px-16 py-2 bg-[url('assets/btnbg.png')] bg-[length:100%_100%] text-center `} onClick={()=>setModalStatus(!modalStatus)}>DIZ-NOS O QUE PRECISAS!</button> */}
        </div>
</div>
</div>

{modalStatus ?
       
<div className='absolute w-full h-full bg-white/80 '>
<div className='text-black absolute  bottom-32 lg:bottom-20 max-lg:left-10  lg:right-10 w-[80%] lg:w-full  lg:max-w-2xl xl:max-w-3xl bg-white bordery cornerwhite '>
               <a className='absolute right-0 top-2 flex justify-end px-2 pt-1 cursor-pointer' onClick={()=>setModalStatus(!modalStatus)} >
              <svg className="h-8 w-8 group-hover:rotate-180 transition duration-300 ease-in-out" fill="white"
              viewBox="0 0 24 24" strokeWidth="0.5" stroke="currentColor" aria-hidden="true">
              <path d="M6 18L18 6M6 6l12 12" />

            </svg>
              </a>
              <div className='w-full flex flex-col lg:flex-row justify-center items-center lg:gap-20 pt-5 pb-6 lg:pb-12'>
              <p className='w-full text-[#0000ff] font-bold text-md lg:text-3xl flex px-6 lg:justify-end items-center leading-tight'>
              <span>
              DIZ-NOS O QUE<br />
              PRECISAS! 
              </span>
              </p>
              <p className='w-full text-md italic lg:text-2xl flex  px-6 lg:justify-start items-start pt-4 lg:pt-4 leading-tight'>
                <span>
                <a href='mailto:geral@uppout.com'>geral@uppout.com</a> <br /><span className='block h-[15px] lg:hidden' ></span>
              <a href='tel:+351215862031'>+351 215 862 031</a><br /><span className='block h-[15px] ' ></span>
              <a href="https://maps.app.goo.gl/rwn3PVecz5K6GfFUA" rel="nofollow" target='_blank' className='text-md italic lg:text-sm'>Rua Garridas 8,<br className='block lg:hidden' /> 1500-618 Lisboa</a>
                </span>
             
              </p>
              </div>
              </div></div>

         :""
        }
</section>
   </main>
   </>
  )
}

export default Home